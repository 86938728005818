import * as React from "react";
import { css, useTheme } from "@emotion/react";

import Navigation from "../Navigation";
import Footer from "../Footer";
import Seo from "../Seo";

const constainerStyle = (theme) => css`
  min-height: 100vh;
  padding: 0 20px;
`;

const wrapper = css`
  max-width: 900px;
  margin: 50px auto;
`;

const Rodo = () => {
  const theme = useTheme();
  return (
    <>
      <Seo
        language={"pl"}
        title={"Klauzula informacyjna o przetwarzaniu danych osobowych"}
      />
      <Navigation
        foreground={theme.colors.whiteText}
        background={theme.colors.red}
      />
      <div css={constainerStyle}>
        <div>
          <article css={wrapper}>
            <h1>KLAUZULA INFORMACYJNA O PRZETWARZANIU DANYCH OSOBOWYCH</h1>
            <h2>Informacja o przetwarzaniu Twoich danych osobowych</h2>
            <p>
              W związku z realizacją wymogów Rozporządzenia Parlamentu
              Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
              danych „RODO”), informujemy o zasadach przetwarzania Twoich danych
              osobowych oraz o przysługujących Tobie prawach z tym związanych.
            </p>
            <h3>I. Administrator danych osobowych</h3>
            <p>
              Administratorem Twoich danych osobowych jest BWA Wrocław Galerie
              Sztuki Współczesnej, z siedzibą we Wrocławiu, ul. Ruska 46a,
              segment B, lokal 103, 50-079 Wrocław, wpisanym do Rejestru
              Instytucji Kultury prowadzonego przez Samorząd Gminy Wrocław pod
              numerem RIK 20/94, NIP: 896-000-58-45, REGON: 000278391.
            </p>
            <h3>II. Inspektor Ochrony Danych</h3>
            <p>
              Wyznaczyliśmy Inspektora Ochrony Danych, z którym możesz się
              skontaktować w sprawach ochrony danych osobowych i realizacji
              swoich praw za pośrednictwem poczty elektronicznej pod adresem:
              iod@bwa.wroc.pl lub pisemnie pod adresem naszej siedziby
              określonym w pkt. I
            </p>
            <h3>III. Cele i podstawy przetwarzania</h3>
            <p>
              Twoje dane przetwarzane są w celu niżej określonym:
              <ul>
                <li>Realizacja gry miejskiej GlassGo</li>
              </ul>
            </p>
            <h3>IV. Kategoria Twoich danych, które przetwarzamy</h3>
            <p>Nie dotyczy.</p>
            <h3>V. Odbiorcy danych</h3>
            <p>Jury konkursu i organizatorzy gry gry miejskiej GlassGo</p>
            <h3>
              VI. Przekazywanie danych do państw trzecich lub organizacji
              międzynarodowych
            </h3>
            <p>
              Nie przekazujemy Twoich danych poza terytorium Polski, Unii
              Europejskiej, Europejskiego Obszaru Gospodarczego.
            </p>
            <h3>VII. Okres przechowywania danych</h3>
            <p>
              Twoje dane osobowe będą przechowywane przez okres niezbędny do
              realizacji celów określonych w pkt III, a po tym czasie przez
              okres oraz w zakresie wymaganym przez przepisy powszechnie
              obowiązującego prawa.
            </p>
            <p>
              Twoje dane pozyskane w celu realizacji powyższego przechowujemy do
              czasu cofnięcia zgody.
            </p>
            <h3>VIII. Twoje prawa</h3>
            <p>
              W związku z przetwarzaniem Twoich danych osobowych przysługują Ci
              następujące prawa:
            </p>
            <ul>
              <li>
                prawo dostępu do danych osobowych, w tym prawo do uzyskania
                kopii tych danych;
              </li>
              <li>
                prawo do żądania sprostowania (poprawiania) danych osobowych – w
                przypadku gdy dane są nieprawidłowe lub niekompletne;
              </li>
              <li>
                prawo do żądania usunięcia danych osobowych (tzw. prawo do bycia
                zapomnianym), w przypadku gdy Twoim zdaniem nie ma podstaw
                abyśmy je dalej przetwarzali;
              </li>
              <li>
                prawo do żądania ograniczenia przetwarzania danych osobowych –
                możesz żądać, abyśmy ograniczyli przetwarzanie Twoich danych
                osobowych wyłącznie do ich przechowywania lub wykonywania
                działań z Tobą uzgodnionych, jeżeli Twoim zdaniem mamy
                nieprawidłowe dane, przetwarzamy je bezpodstawnie lub nie
                chcesz, żebyśmy je usunęli, bo potrzebne są od ustalenia,
                dochodzenia lub obrony roszczeń, lub na czas wniesionego przez
                Ciebie sprzeciwu względem przetwarzania danych;
              </li>
              <li>
                prawo sprzeciwu wobec przetwarzania danych: - sprzeciwu
                marketingowego – masz prawo sprzeciwu wobec przetwarzania Twoich
                danych w celu prowadzenia marketingu bezpośredniego. Jeśli
                skorzystasz z tego prawa, zaprzestaniemy przetwarzania danych w
                tym celu lub - sprzeciwu z uwagi na szczególną sytuację – masz
                także prawo sprzeciwu wobec przetwarzania Twoich danych na
                podstawie uzasadnionego interesu w celach innych niż marketing
                bezpośredni, a także gdy przetwarzanie jest niezbędne do
                wykonania zadania realizowanego w interesie publicznym lub w
                ramach sprawowania władzy publicznej przez Administratora.
                Powinnaś/powinieneś wtedy wskazać nam Twoją szczególną sytuację,
                która Twoim zdaniem uzasadnia zaprzestanie przez nas
                przetwarzania danych objętych sprzeciwem. Przestaniemy
                przetwarzać Twoje dane w tych celach, chyba że wykażemy, iż
                podstawy przetwarzania przez nas Twoich danych są nadrzędne
                wobec Twoich praw lub też że Twoje dane są nam niezbędne do
                ustalenia, dochodzenia lub obrony roszczeń.
              </li>
              <li>
                prawo do przenoszenia danych – masz prawo do otrzymania od nas w
                ustrukturyzowanym, powszechnie używanym formacie nadającym się
                do odczytu maszynowego danych osobowych Ciebie dotyczących,
                które nam dostarczyłaś/eś na podstawie zgody. Możesz też zlecić
                nam przesłanie tych danych bezpośrednio innemu podmiotowi.
              </li>
              <li>
                prawo do wniesienia skargi do organu nadzorczego. Jeśli uważasz,
                że przetwarzamy Twoje dane niezgodnie z prawem, możesz złożyć w
                tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych
                lub innego właściwego organu nadzorczego.
              </li>
              <li>
                prawo do cofnięcia zgody na przetwarzanie danych osobowych. W
                każdej chwili masz prawo cofnąć zgodę na przetwarzanie danych
                osobowych, które przetwarzamy na podstawie Twojej zgody.
                Cofnięcie zgody nie ma wpływu na zgodność przetwarzania, którego
                dokonano na podstawie zgody przed jej cofnięciem, zgodnie z
                obowiązującym prawem.
              </li>
            </ul>
            <p>
              Szczegóły jak wykonywać swoje prawa znajdziesz na stronie
              internetowej Prezesa Urzędu Ochrony Danych Osobowych. Możesz także
              skierować zapytanie do naszego Inspektora Ochrony Danych.
            </p>
            <h3>
              IX. Informacja o wymogu/dobrowolności podania danych osobowych.
            </h3>
            <p>
              Podanie przez Ciebie danych jest:
              <ul>
                <li>dobrowolne</li>
              </ul>
              <p>Jeśli nie podasz nam danych:</p>
              <ul>
                <li>
                  niepodanie danych może skutkować odmową wzięcia udziału w
                  konkursie GlassGo
                </li>
              </ul>
              <h3>X. Informacja o źródle danych</h3>
              <p>Nie dotyczy</p>
              <p>
                Twoje dane mogą być przetwarzane w sposób zautomatyzowany i nie
                będą profilowane.
              </p>
              <p>
                WIĘCEJ INFORMACJI NA TEMAT SPOSOBU REALIZACJI TWOICH PRAW I
                INNYCH OKOLICZNOŚCI UJĘTYCH W NINIEJSZEJ INFORMACJI MOŻESZ
                UZYSKAĆ KONTAKTUJĄC SIĘ W INSPEKTOREM OCHRONY DANYCH LUB
                ADMINISTRATOREM.
              </p>
              <p>
                ADMINISTRATOR PODEJMUJE ODPOWIEDNIE ŚRODKI, ABY W ZWIĘZŁEJ,
                PRZEJRZYSTEJ, ZROZUMIAŁEJ I ŁATWO DOSTĘPNEJ FORMIE, JASNYM I
                PROSTYM JĘZYKIEM – W SZCZEGÓLNOŚCI GDY INFORMACJE SĄ KIEROWANE
                DO DZIECKA – UDZIELIĆ OSOBIE, KTÓREJ DANE DOTYCZĄ, WSZELKICH
                INFORMACJI, O KTÓRYCH MOWA W ART. 13 I 14 RODO, ORAZ PROWADZIĆ Z
                NIĄ WSZELKĄ KOMUNIKACJĘ NA MOCY ART. 15–22 I 34 RODO W SPRAWIE
                PRZETWARZANIA.
              </p>
            </p>
          </article>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rodo;
